@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

html {
  position: relative;
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  font-family: "Inter", sans-serif;
  background: #060d12;
  margin: 0;
  padding: 0;
  overflow: auto;
}

nav {
  margin: auto;
  max-width: 1200px;
  padding: 20px;
}

#in-dev-screen {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#in-development-frame {
  max-width: 800px;
  margin: 4rem auto;

  --grid-layout-gap: 1em;
  --grid-column-count: 3;
  --grid-item--min-width: 200px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: 350px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

#mobile-drawer-wrapper {
  background-color: white;
  height: 100%;
  padding: 60px;
}

#mobile-drawer-content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.MuiIconButton-colorPrimary {
  color: white !important;
}

#loadingScreenFrame {
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  p {
    color: white;
    font-size: 24px;
    text-align: center;
  }
}

#loadingScreenloader {
  border: 10px solid #e5d6ec;
  border-radius: 50%;
  border-top: 10px solid #7f34a1;
  width: 100px;
  height: 100px;
  animation: spinner 1s linear infinite;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#logo-social-links {
  display: flex;
  align-items: center;
}

#site-links {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#nav-logo {
  width: 120px;
  margin-right: 50px;
}

.nav-social-logo {
  width: 32px;
  padding: 5px;
}

.nav-btn {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.nav-btn:hover {
  transition: all ease-in-out 0.16s;
  color: #7f34a1;
}

.land-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

#land-page-title-img-box {
  display: flex;
  flex-direction: row;
  margin: 2rem 0 10px;
  align-items: center;
  gap: 20px;
}

#title {
  color: white;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #7f34a1;
  font-size: 64px;
  text-transform: uppercase;
  letter-spacing: -0.2rem;
  margin: 0;
}

#caedrel-face {
  width: 64px;
}

#available-picks-box {
  background-color: rgba(255, 255, 255, 0.094);
  width: 80%;
  margin: 6rem auto;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 0px 0px rgba(33, 7, 26, 0.25);
}

#title-pulse {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.094);
}

.green {
  background: #66ff99;
}

.red {
  background: #ff6666;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

#available-picks-title {
  font-size: 16px;
  margin: 0;
  color: white;
  text-transform: uppercase;
  padding-bottom: 10px;
}

#picks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.prediction {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  text-decoration: none;
  width: 80%;
  justify-content: space-evenly;
  background-color: #ffffff23;
  margin-bottom: 32px;
  border-radius: 6px;
  transition: 0.2s;
  cursor: pointer;
}

.prediction:hover {
  background-color: #2c1836;
  box-shadow: 0 0 10px 2px rgba(127, 52, 161, 0.3);
  border: 1px solid #7f34a1;
}

.team {
  display: flex;
  flex-direction: row;
  min-width: 190px;
  align-items: center;
  justify-content: space-between;
}

.vote-box {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  padding: 4px 10px;
  min-width: 40px;
  display: flex;
  justify-content: center;
}

.team-abbr {
  font-size: 16px;
  padding: 10px;
  color: white;
}

.team-logo {
  width: 64px;
}

.prediction-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

#prediction-team-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
}

#prediction-team {
  background-color: #ffffff23;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  align-items: center;
  transition: 0.2s;
  justify-content: center;
  cursor: pointer;
  width: 400px;
  padding: 10px;
  border-radius: 6px;
}

#prediction-team-selected {
  background-color: #ffffff23;
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 10px;
  border-radius: 6px;
}

#prediction-team-none {
  background-color: #ffffff23;
  opacity: 0.5;
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 10px;
  border-radius: 6px;
}

#prediction-team-selected-live {
  background-color: #2c1836;
  border: 2px solid #7f34a1;
  box-shadow: 0 0 10px 2px rgba(127, 52, 161, 0.3);
  display: flex;
  max-width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 10px;
  border-radius: 6px;
}

#team-logo-prediction {
  width: 200px;
}

#prediction-page-vs {
  padding: 14px;
  text-align: center;
}

#prediction-page-vs p {
  color: white;
  font-size: 32px;
}

#prediction-page-warn {
  background-color: #0000005f;
  backdrop-filter: blur(10px);
  border: 1px solid #0000006c;
  width: 90%;
  border-radius: 6px;
  position: absolute;
  font-size: 32px;
  text-align: center;
}

#prediction-team:hover {
  background-color: #2c1836;
  border: 2px solid #7f34a1;
  box-shadow: 0 0 10px 2px rgba(127, 52, 161, 0.3);
}

#sign-in-btn {
  display: flex;
  margin: auto;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  background-color: #7289d9;
  border-radius: 6px;
  box-shadow: 0px 4px 0px 0px #354475;
  padding: 10px;
  border-width: 0;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: white;
}

#sign-in-btn-home {
  display: flex;
  margin-left: 2rem;
  transition: 0.2s;
  align-items: center;
  cursor: pointer;
  background-color: #7289d9;
  border-radius: 6px;
  box-shadow: 0px 4px 0px 0px #354475;
  padding: 10px;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: bold;
  line-height: inherit;
  color: white;
}

#sign-in-btn-home-sm {
  display: none;
  transition: 0.2s;
  align-items: center;
  cursor: pointer;
  background-color: #7289d9;
  border-radius: 6px;
  box-shadow: 0px 4px 0px 0px #354475;
  padding: 10px;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: white;
}

#sign-in-btn-home:hover {
  transform: translateY(-2px);
  background-color: #4c61a4;
  box-shadow: 0px 4px 0px 0px #242f54;
}

#sign-in-btn:hover {
  transform: translateY(-2px);
}

#search-btn:hover {
  transform: translateY(-2px);
}

.search-result {
  border: 1px solid #ffffff66;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 2rem auto;
  border-radius: 6px;
  padding: 10px;
}

#sign-in-modal {
  border-radius: 6px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
}

#live-votes {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leaderboard-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* .MuiDataGrid-row[data-rowindex="0"]{
  background-color: rgb(238, 201, 54);
  font-size: 28px;
}

.MuiDataGrid-row[data-rowindex="1"]{
  background-color: #d1d7da;
  font-size: 28px;
}

.MuiDataGrid-row[data-rowindex="2"]{
  background-color: #b56a0e;
  font-size: 28px;
} */

#change-vote-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 0px 0px #454545;
  padding: 10px;
  transition: 0.2s;
  font-weight: 500;
  border-width: 0;
  font-family: inherit;
  font-size: 20px;
  font-style: inherit;
  line-height: inherit;
  color: rgb(0, 0, 0);
}

#change-vote-btn:hover {
  transform: translateY(-2px);
  background-color: rgb(37, 37, 37);
  box-shadow: 0px 4px 0px 0px #181818;
  color: white;
}

#selected-vote-box {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

#search-input-box {
  display: flex;
  padding: 5px;
  flex-direction: row;
  align-items: center;
}

#search-input {
  background-color: rgba(255, 255, 255, 0.094);
  color: white;
  margin-left: 5px;
  width: 20%;
  padding: 10px;
  font-size: 18px;
  border: none;
  outline: none;
}

#search-btn {
  cursor: pointer;
  margin-left: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 0px 0px #494949;
  transition: 0.2s;
  color: black;
  border-radius: 6px;
  padding: 10px;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

#my-prediction-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  padding-bottom: 2rem;
}

#land-page-title-box {
  margin-bottom: 2rem;
}

#user-predictions-score {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: auto;
  align-items: center;
  flex-direction: row;
}

#user-correct-predictions-box {
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 14%);
  border-radius: 4px;
}

#my-prediction-list {
  max-width: 1100px;
  margin: 4rem auto;

  --grid-layout-gap: 1em;
  --grid-column-count: 4;
  --grid-item--min-width: 250px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.my-prediction-object {
  aspect-ratio: 1/1;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
}

.my-prediction-object:hover {
  background-color: #2c1836;
}

.my-prediction-object-won {
  border: 2px solid rgba(0, 226, 88, 0.7);
  box-shadow: rgba(0, 226, 88, 0.35) 0px 0px 12px;
}

.my-prediction-object-loss {
  border: 2px solid rgba(255, 61, 61, 0.7);
  box-shadow: rgba(255, 61, 61, 0.35) 0px 0px 12px;
}

.my-prediction-object-none {
  border: 2px solid rgba(144, 144, 144, 0.2);
}

.my-prediction-object-active {
  border: 2px solid rgba(144, 144, 144, 0.2);
}

.my-prediction-cover-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  top: 0;
  z-index: 2;
  background: linear-gradient(133deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 50%, rgba(255, 255, 255, 0.04) 51%, rgba(255, 255, 255, 0.04) 100%);
  display: flex;
  flex-direction: column;
}

.my-prediction-content-title {
  height: 46px;
  padding: 8px 8px 0px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-prediction-content-title p {
  margin: 0;
  color: white;
  font-family: "Inter", sans-serif;
}

.my-prediction-content-title div p:first-child {
  font-weight: 600;
}

.my-prediction-content-title div p:last-child {
  font-weight: 400;
}

.my-prediction-title-frame {
  position: relative;
  border-radius: 4px 4px 0px 0px;
  height: 54px;
  z-index: -1;
  width: 100%;
  background-color: #16181b;
}

.my-prediction-status {
  border-radius: 3px;
  font-weight: 700 !important;
  padding: 2px 6px;
  font-size: 12px;
}

.my-prediction-status-none {
  background-color: #909090;
}

.my-prediction-status-won {
  background-color: #00e258;
}

.my-prediction-status-loss {
  background-color: #ff3d3d;
}

.my-prediction-status-vote-now {
  background-color: #7f34a1;
}

.my-prediction-status-active {
  display: none;
}

.my-prediction-team-photo {
  position: relative;
  flex-grow: 1;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-prediction-team-photo img {
  width: 60%;
  aspect-ratio: 1/1;
  display: block;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.pulse-red {
  animation: pulse-animation-red 2s infinite;
}

#region-select-card-frame {
  position: relative;
  border-radius: 4px 4px 0px 0px;
  height: 54px;
  z-index: -1;
  width: 100%;
  background-color: #16181b;
}

#regionSelectScreenFrame {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

#region-select-list {
  max-width: 1300px;
  margin: 4rem 0;

  --grid-layout-gap: 1em;
  --grid-column-count: 4;
  --grid-item--min-width: 250px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.region-select-object {
  aspect-ratio: 1/1;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
}

.region-select-object-cover-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  top: 0;
  z-index: 2;
  background: linear-gradient(133deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 50%, rgba(255, 255, 255, 0.04) 51%, rgba(255, 255, 255, 0.04) 100%);
  display: flex;
  flex-direction: column;
}

.region-select-photo {
  position: relative;
  flex-grow: 1;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.region-select-photo img {
  width: 60%;
  display: block;
  transition: all .2s ease-in-out;
}

.region-select-photo img:hover {
  width: 70%;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(46, 120, 58, 0.517);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(23, 88, 50, 0);
  }
}

@keyframes pulse-animation-red {
  0% {
    box-shadow: 0 0 0 0px rgba(120, 46, 46, 0.517);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(88, 23, 23, 0);
  }
}

@media screen and (max-width: 900px) {
  #site-links {
    display: none;
  }

  .nav-btn {
    color: rgb(0, 0, 0);
    margin-left: 0;
  }

  #sign-in-btn-home {
    margin-left: 0;
  }

  #mobile-sign-in-box {
    display: "flex" !important;
    flex-direction: "column";
    align-items: "center";
    gap: "20px";
  }

  #sign-in-btn-home-sm {
    display: flex;
  }

  #sign-in-btn-home {
    display: none;
  }

  #social-links {
    display: none;
  }
}

@media screen and (max-width: 740px) {
  body {
    overflow-y: scroll;
  }

  #land-page-title-img-box {
    margin: 0;
  }

  #land-page-title-box {
    text-align: center;
  }

  #title {
    font-size: 52px;
    letter-spacing: -0.1;
  }

  #worlds-logo {
    margin-top: 1rem;
    width: 180px;
  }

  #land-page-title-img-box {
    flex-direction: column;
  }

  #available-picks-box {
    margin: 4rem auto;
  }

  .prediction {
    width: 100%;
  }

  #team-logo-prediction {
    width: 120px;
  }

  #prediction-team {
    width: 300px;
  }

  #prediction-team-selected {
    width: 300px;
  }

  #selected-vote-box {
    margin-top: 6rem;
  }

  #prediction-team-box {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .team {
    min-width: auto;
    flex-direction: column;
    align-items: stretch;
  }

  .vote-box{
    margin-top: 4px;
  }

  #team-right {
    flex-direction: column-reverse;
  }
  
  .prediction{
    padding-bottom: 10px;
  }

}

@media screen and (max-width: 400px) {
  .nav-social-logo {
    width: 22px;
  }

  .team-logo {
    width: 40px;
    height: 40px;
  }

  #prediction-team {
    width: 200px;
  }

  #prediction-team-selected {
    width: 200px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
